<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2022-12-05 11:25:27
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @LastEditTime: 2022-12-05 16:17:54
 * @FilePath: \web\src\views\invoicing\productInfo\edit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="app-container">
		<edit/>
	</div>
</template>

<script>
import edit from './add.vue'
export default {
	name: 'editPoductInfo',
	components: {
		edit,
	},
    data(){
        return{
            
		}
	},
    methods: {
    
	}
}
</script>