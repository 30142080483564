<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2022-12-05 11:25:27
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @LastEditTime: 2023-01-12 09:02:28
 * @FilePath: \web\src\views\invoicing\productInfo\add copy.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="app-container" v-loading='isLoading'>    
        <div class="left">
            <el-input
                class="filterText"
                placeholder="输入关键字进行过滤"
                v-model="filterText">
            </el-input>
            <el-tree
                v-if="refreshTree"
                class="filter-tree"
                :data="treeData"
                :props="defaultProps"
                :default-checked-keys='checkedKeys'
                :default-expand-all='expandAll'
                :filter-node-method="filterNode"
                v-model="filterText"
                node-key="id"
                @node-click="handleNodeClick"
                highlight-current
                ref="tree">
            </el-tree>
        </div>
        <div class="right">
            <el-form ref="ruleForm" class="ruleForm" :model="ruleForm" :rules="rules" label-width="7rem">
                <el-form-item label="商品类别" prop="categoryName" required>
                    <el-input v-model="ruleForm.categoryName" disabled></el-input>
                </el-form-item> 
                <el-form-item label="税收分类编码" prop="categoryCode" required>
                    <el-input v-model="ruleForm.categoryCode" disabled></el-input>
                </el-form-item>
                <el-form-item label="商品名称" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入商品名称"></el-input>
                </el-form-item>
                <el-form-item label="规格型号" prop="spec">
                    <el-input v-model="ruleForm.spec" placeholder="请输入规格型号(选填)"></el-input>
                </el-form-item>
                <el-form-item label="单位" prop="unit">
                    <el-input v-model="ruleForm.unit" placeholder="请输入单位(选填)"></el-input>
                </el-form-item>
                <el-form-item label="单价" prop="price" class='price'>
                    <el-input-number v-model="ruleForm.price" :controls='false' :min="1" :max="999999999.99" placeholder="请输入单价(选填)"></el-input-number>
                </el-form-item>
                
                <el-form-item label="单价含税" prop="isPriceIncludeTax">
                    <el-radio-group v-model="ruleForm.isPriceIncludeTax" >
                        <el-radio label="1" value="1">是</el-radio>
                        <el-radio label="0" value="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="税率" prop="taxRate" :rules=" [{ required: true, message: '请选择税率', trigger: 'blur',},]" >
                    <el-select v-model="ruleForm.taxRate" placeholder="请选择税率" class='filterInput' >
                        <el-option v-for="item in rateList" :key="item.id" :label="item.label" :value="item.value"/>
                    </el-select>
                </el-form-item>
                
                <el-form-item label="免税" prop="isFreeTax">
                    <el-radio-group v-model="ruleForm.isFreeTax" @change="changeFreeTax">
                        <el-radio label="1" value="1">是</el-radio>
                        <el-radio label="0" value="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="免税类型" prop="freeTaxType" :rules=" ruleForm.isFreeTax == '1' ? [{ required: true, message: '请选择税率', trigger: 'blur',},] : [{message: '请选择税率', trigger: 'blur',}]" >
                    <el-select v-model="ruleForm.freeTaxType" placeholder="请选择免税类型" class='filterInput' :disabled="ruleForm.isFreeTax == '0'">
                        <el-option v-for="item in taxExemptTypeList" :key="item.id" :label="item.label" :value="item.value"/>
                    </el-select>
                </el-form-item>


                <el-form-item label="享受优惠政策" prop="hasPreferPolicy" @change="changePreferPolicy">
                    <el-radio-group v-model="ruleForm.hasPreferPolicy">
                        <el-radio label="1" value="1">是</el-radio>
                        <el-radio label="0" value="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="优惠政策类型" prop="preferPolicyType" :rules=" ruleForm.hasPreferPolicy == '1' ? [{ required: true, message: '请选择税率', trigger: 'blur',},] : [{message: '请选择税率', trigger: 'blur',}]" >
                    <el-select v-model="ruleForm.preferPolicyType" placeholder="请选择优惠政策类型" class='filterInput' :disabled="ruleForm.hasPreferPolicy == '0'">
                        <el-option v-for="item in favouredPolicyTypeList" :key="item.id" :label="item.label" :value="item.value"/>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit" class="itemBtn" :loading='submitLoading'>{{$route.query.id?'保存':'新增'}}</el-button>
                    <el-button @click="goBack" class="itemBtn">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
        filterText: '',
        treeData: [{
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2'
          }]
        }],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        checkedKeys:[],
        expandAll:false,
        refreshTree: false,
        rateList: [ //税率列表
             {label: '0%',value: '0'},
             {label: '1%',value: '1'},
             {label: '3%',value: '3'},
             {label: '4%',value: '4'},
             {label: '5%',value: '5'},
             {label: '6%',value: '6'},
             {label: '9%',value: '9'},
             {label: '10%',value: '10'},
             {label: '11%',value: '11'},
             {label: '13%',value: '13'},
             {label: '16%',value: '16'},
             {label: '17%',value: '17'},
        ], 
        taxExemptTypeList: [ //免税类型列表
             {label: '出口免税和其他免税优惠政策（免税）',value: '1'},
             {label: '不征增值税（不征税）',value: '2'},
             {label: '普通零税率（0%）',value: '3'},
        ],
        favouredPolicyTypeList: [ //优惠政策类型列表
            {label: '免税',value: '1'},
            {label: '100%先征后退',value: '2'},
            {label: '50%先征后退',value: '3'},
            {label: '即征即退30%',value: '4'},
            {label: '即征即退50%',value: '5'},
            {label: '即征即退70%',value: '6'},
            {label: '即征即退100%',value: '7'},
            {label: '按3%简易征收',value: '8'},
            {label: '按5%简易征收',value: '9'},
            {label: '按5%简易征收减按1.5%计征',value: '10'},
            {label: '稀土产品',value: '11'},
            {label: '简易征收',value: '12'},
            {label: '不征税',value: '13'},
            {label: '先征后退',value: '14'},
            {label: '超税负3%即征即退',value: '15'},
            {label: '超税负8%即征即退',value: '16'},
            {label: '超税负12%即征即退',value: '17'},
        ],
        ruleForm: {
          categoryName :'',
          spbmjc:'',
          categoryCode :'',
          name :'',
          spec:'',
          unit:'',
          price: undefined,
          isPriceIncludeTax:'1',
          taxRate:'',
          isFreeTax:'0',
          freeTaxType:'',
          hasPreferPolicy:'0',
          preferPolicyType:'',
        },
        rules: {
            name: [
                { required: true, message: '请输入商品名称', trigger: 'blur' },
                { min: 1, max: 90, message: '不超过90个字符', trigger: 'blur' }
            ],
            spec: [
                { min: 1, max: 40, message: '不超过40个字符', trigger: 'blur' }
            ],
            unit: [
                { min: 1, max: 20, message: '不超过20个字符', trigger: 'blur' }
            ],
            price: [
                // { min: 0, max: 999999999.99, message: '不超过最大数字', trigger: 'blur' }
            ],
        },
        submitLoading:false,
        isLoading:false,
      };
    },
    computed: {
      ...mapGetters([
        'treeDatas',
      ]),
    },

    methods: {
        handleNodeClick(data) {
            if(data.children.length  == 0 ){
                this.ruleForm.categoryName = data.label
                this.ruleForm.categoryCode = data.id
                this.ruleForm.spbmjc = data.spbmjc
            }
            this.$refs.tree.setCurrentKey(this.ruleForm.categoryCode)
        },
        changeFreeTax(){
            if(this.ruleForm.isFreeTax == '0'){
                this.ruleForm.freeTaxType = ''
            }
        },
        changePreferPolicy(){
            if(this.ruleForm.hasPreferPolicy == '0'){
                this.ruleForm.preferPolicyType = ''
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        onSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.submitLoading = true;
                    let value = this.$route.query.id ? 'EditCommodity' : 'AddCommodity'
                    this.$store.dispatch(value, {
                        ...this.ruleForm,
                        userId : this.getToken('userId'),
                        companyId : this.getToken('companyId'),
                    }).then(res => {
                    if (res.success) {
                        einvAlert.success("提示",'处理成功')
                        this.$router.back();
                    } else {
                        this.submitLoading = false;
                        einvAlert.error("提示",res.msg)
                    }
                    }).catch(err => {
                    einvAlert.error("提示",err)
                    this.submitLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        goBack(){
            this.$router.back()
        },
        filterData(data) { //前端校验路由
            const list = data.filter(item => {
                item.id = item.bm;
                item.label = item.mc;
                item.disabled = item.children.length > 0 ? true : false
                if (item.children && item.children.length) {  //遍历子集children
                    item.children = this.filterData(item.children)
                }
                return true
                })
            return list
        },
        findCategoryList(){
            this.isLoading = true;
             this.$store.dispatch("FindCategoryList", {
                name : this.filterText,
                companyId : this.getToken('companyId'),
            }).then(res => {
            if (res.success) {
                this.treeData = this.filterData(res.data)
                this.$store.commit('SET_TREEDATA',this.treeData);
                if(this.$route.query.id){
                    this.refreshTree = true;
                    this.expandAll = true;
                    this.$nextTick(() => {  // 默认高亮选中节点
                        this.$refs.tree.setCurrentKey(this.ruleForm.categoryCode)
                    })
                }else{
                    this.refreshTree = true;
                    this.$nextTick(() => {  // 默认高亮选中节点
                        this.$refs.tree.setCurrentKey('1010101010000000000')
                        this.ruleForm.categoryCode = '1010101010000000000'
                        this.ruleForm.categoryName = '稻谷'
                        this.ruleForm.spbmjc = '谷物'
                    })
                }
                this.isLoading = false;
            } else {
                this.isLoading = false;
                einvAlert.error("提示",res.msg)
            }
            })
        },
        findOneCommodity(){
            this.isLoading = true;
             this.$store.dispatch("FindOneCommodity", {
                id : this.$route.query.id,
                companyId : this.getToken('companyId'),
            }).then(res => {
            if (res.success) {
                this.isLoading = false;
                this.ruleForm = res.data;

                this.refreshTree = true;
                this.expandAll = true;
                this.$nextTick(() => {  // 默认高亮选中节点
                    this.$refs.tree.setCurrentKey(res.data.categoryCode)
                })
                
            } else {
                this.isLoading = false;
                einvAlert.error("提示",res.msg)
            }
            })
        },
    },
    mounted(){
        if(this.$route.query.id){
            this.findOneCommodity();
        }
        if(this.treeDatas && this.treeDatas.length > 0 ){
            this.treeData = this.treeDatas;
            if(!this.$route.query.id ){
                this.refreshTree = true;
                this.$nextTick(() => {  // 默认高亮选中节点
                    this.$refs.tree.setCurrentKey('1010101010000000000')
                    this.ruleForm.categoryCode = '1010101010000000000'
                    this.ruleForm.categoryName = '稻谷'
                    this.ruleForm.spbmjc = '谷物'
                })
            }
        }else{
            this.findCategoryList();
        }
    },

    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
  };
</script>

<style lang="less" scoped>
    .app-container{
        display: flex;
        height: 100% !important;
        justify-content: space-between;
        .left{
            width: 42%;
            padding-left: 20px;
            background: #fff;
        }
        .right{
            width: 57%;
            background: #fff;
            .ruleForm{
                width: 440px;
                margin: 20px auto 0;
                .filterInput{
                    width: 100%;
                }
                .price{
                    /deep/ .el-input-number--medium{
                        width: 100% !important;
                    }
                    /deep/ .el-input-number .el-input__inner {
                        text-align: left; //位置居左
                    }
                }
                .itemBtn{
                    width: 120px;
                    margin-right: 20px;
                }
            }
        }
    }
    .filterText{
        width: 300px;
        margin: 20px 0 ;
    }
    .filter-tree{
        width: 100%;
        height: 90%;
        overflow-y: auto;
    }
    /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
        background-color: #8fc6ff !important;
    }
</style>